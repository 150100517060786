package it.neckar.react.common.loading

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.unit.other.pct
import it.neckar.react.common.*
import kotlinx.css.*
import kotlinx.html.classes
import react.*
import react.dom.*
import styled.*
import kotlin.math.roundToInt


fun RBuilder.bootstrapProgressBar(
  title: String,
  progressType: ProgressType,
  currentPercentage: @pct Double,
  style: ProgressBarStyle = ProgressBarStyle.Default,
): Unit = child(bootstrapProgressBar) {
  attrs {
    this.title = title
    this.progressType = progressType
    this.currentPercentage = currentPercentage
    this.style = style
  }
}

val bootstrapProgressBar: FC<ProgressProps> = fc("bootstrapToast") { props ->
  @pct val currentPercentage = props::currentPercentage.safeGet()
  val percent100 = (currentPercentage * 100.0).roundToInt()

  val title = props::title.safeGet()
  val progressType = props::progressType.safeGet()
  val style = props::style.safeGet()

  styledDiv {
    attrs {
      classes = setOf("progress")
    }
    css {
      if (style == ProgressBarStyle.Small) height = 5.px
    }
    styledDiv {
      attrs {
        classes = setOf("progress-bar")
        when (progressType) {
          ProgressType.Info -> addClass("bg-info")
          ProgressType.Warning -> addClass("bg-warning")
          ProgressType.Danger -> addClass("bg-danger")
          ProgressType.Success -> addClass("bg-success")
        }
        roleHTML = "progressbar"
        ariaValueRange = IntRange(0, 100)
        ariaValueNow = percent100
        ariaLabel = title
      }
      css {
        width = percent100.pct
        if (style == ProgressBarStyle.Small) height = 5.px
      }
    }
  }
}

enum class ProgressType {
  Info,
  Warning,
  Danger,
  Success,
}

enum class ProgressBarStyle {
  /**
   * Default size
   */
  Default,

  /**
   * Small size - e.g. for the header
   */
  Small,
}


external interface ProgressProps : Props {
  var title: String
  var progressType: ProgressType
  var currentPercentage: @pct Double
  var style: ProgressBarStyle
}
