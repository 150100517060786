package it.neckar.react.common

import react.*
import react.dom.*

object FontAwesome {
  /**
   * Adds a sign-out span
   */
  fun RBuilder.faSignOut() {
    icon(FontAwesomeIcons.signOut)
  }

  fun RBuilder.faBug() {
    icon(FontAwesomeIcons.bug)
  }

  fun RBuilder.faHome() {
    icon(FontAwesomeIcons.home)
  }

  fun RBuilder.faEdit() {
    icon(FontAwesomeIcons.edit)
  }

  fun RBuilder.faSave() {
    i(classes = FontAwesomeIcons.save) {}
  }

  fun RBuilder.faAdd() {
    i(classes = FontAwesomeIcons.add) {}
  }

  fun RBuilder.faPhone() {
    i(classes = FontAwesomeIcons.phone) {}
  }

  fun RBuilder.faReload() {
    i(classes = FontAwesomeIcons.sync) {}
  }

  fun RBuilder.faCancel() {
    i(classes = FontAwesomeIcons.ban) {}
  }

  fun RBuilder.faGift() {
    i(classes = FontAwesomeIcons.gift) {}
  }

  fun RBuilder.faHorizontalArrows() {
    i(classes = FontAwesomeIcons.horizontalArrows) {}
  }

  fun RBuilder.faVerticalArrows() {
    i(classes = FontAwesomeIcons.verticalArrows) {}
  }

  fun RBuilder.faArrowRight() {
    i(classes = FontAwesomeIcons.arrowRight) {}
  }

  fun RBuilder.faArrowLeft() {
    i(classes = FontAwesomeIcons.arrowLeft) {}
  }

  fun RBuilder.faUser() {
    i(classes = FontAwesomeIcons.user) {}
  }

  fun RBuilder.faAddUser() {
    i(classes = FontAwesomeIcons.addUser) {}
  }

  fun RBuilder.faInformation() {
    i(classes = FontAwesomeIcons.information) {}
  }

  fun RBuilder.faCircleQuestion() {
    i(classes = FontAwesomeIcons.questionCircle) {}
  }

  fun RBuilder.faCircleExclamation() {
    i(classes = FontAwesomeIcons.exclamationCircle) {}
  }

  fun RBuilder.faLockClosed() {
    i(classes = FontAwesomeIcons.lock) {}
  }

  fun RBuilder.faLockOpen() {
    i(classes = FontAwesomeIcons.lockOpen) {}
  }

  fun RBuilder.faEye() {
    i(classes = FontAwesomeIcons.eye) {}
  }

  fun RBuilder.faEyeSlash() {
    i(classes = FontAwesomeIcons.eyeSlash) {}
  }

  fun RBuilder.faCircleCheck() {
    i(classes = FontAwesomeIcons.circleCheck) {}
  }

  fun RBuilder.faMinus() {
    i(classes = FontAwesomeIcons.minus) {}
  }

  fun RBuilder.faInformationCircle() {
    i(classes = FontAwesomeIcons.informationCircle) {}
  }

  fun RBuilder.faSpinner() {
    i(classes = FontAwesomeIcons.busy) {}
  }

  fun RBuilder.faSort() {
    i(classes = FontAwesomeIcons.sort) {}
  }

  fun RBuilder.faSortDown() {
    i(classes = FontAwesomeIcons.sortDown) {}
  }

  fun RBuilder.faSortUp() {
    i(classes = FontAwesomeIcons.sortUp) {}
  }

  fun RBuilder.faTrash() {
    i(classes = FontAwesomeIcons.trash) {}
  }

  /**
   * Creates a new icon using "i" with the given icon class
   */
  fun RBuilder.icon(iconClass: String) {
    i(classes = iconClass) {}
  }
}
