package it.neckar.rest.jwt

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * Represents a pair of JWT tokens
 */
@Serializable
data class JwtTokenPair(
  /**
   * The access token - that has a limited validity date.
   * Must be used to access the data
   */
  val accessToken: JwtToken,
  /**
   * The refresh token that must only be used once to fetch a new token pair
   */
  val refreshToken: JwtToken,
) {
  companion object {
    /**
     * Example object
     */
    val example: JwtTokenPair = JwtTokenPair(
      JwtToken("_the_content_of_the_accessToken"),
      JwtToken("_the_content_of_the_refreshToken")
    )
  }
}
