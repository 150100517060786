package it.neckar.user

import it.neckar.open.annotations.serialization.SerializedInline
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * A user login name.
 * Must not contain spaces or special chars
 */
@Serializable(with = UserLoginNameSerializer::class)
@SerializedInline
data class UserLoginName(val value: String) {
  override fun toString(): String {
    return value
  }

  companion object {
    val NeckarIT: UserLoginName = UserLoginName("neckarIT")
  }
}
