package it.neckar.react.common.button

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import kotlinx.coroutines.*
import kotlinx.html.js.onClickFunction
import kotlinx.html.title
import react.*
import react.dom.*

/**
 * Creates a link with callback
 */
fun RBuilder.linkAction(
  icon: (() -> String)? = null,
  label: (() -> String)? = null,
  title: (() -> String)? = null,
  action: suspend () -> Unit,
): Unit = child(LinkAction) {
  require(icon != null || label != null) {
    "At least one of icon or label must be provided"
  }

  attrs {
    this.icon = icon
    this.label = label
    this.action = action
    this.title = title
  }
}

val LinkAction: FC<LinkActionProps> = fc("linkAction") { props ->
  val icon = props::icon.safeGet()?.invoke()
  val action: suspend () -> Unit = props::action.safeGet()
  val label = props::label.safeGet()?.invoke()
  val title = props::title.safeGet()?.invoke()

  a("#") {
    if (icon != null) {
      i("$icon px-1") { }
    }

    if (label != null) {
      +label
    }

    attrs {
      onClickFunction = useCallback(action) {
        //do *not* follow the link
        it.preventDefault()

        AppScope.launch {
          action()
        }
      }

      if (title != null) {
        this.title = title
      }
    }
  }

}

external interface LinkActionProps : Props {
  var icon: (() -> String)?
  var label: (() -> String)?
  var title: (() -> String)?

  var action: suspend () -> Unit
}
